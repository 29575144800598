import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import Header from '@amzn/awsui-components-react/polaris/header';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import {handleUserAuthnSelection} from '../../handlers/Common/UserDetailsHandler';
import Button from '@amzn/awsui-components-react/polaris/button';
import CenteredContainer from '../../components/Layout/CenteredContainer';
import {isMobile} from 'react-device-detect';
import {fetchAuthenticationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import InternalServerError from '../../components/Alerts/InternalServerError';
import {isLocalEnv} from '../../utils/envHelper';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';

/* eslint-disable @typescript-eslint/no-var-requires */
const qrCodeIcon = require('../../assets/images/qrCodeIcon.svg').default;
const usbIcon = require('../../assets/images/usbIcon.svg').default;

const AuthenticationLandingPage = () => {
  const bundle = useAuthgardBundle();
  const navigate = useNavigate();
  const location = useLocation();
  const [csrfToken, setCsrfToken] = useState<string>(location.state?.csrfToken);
  const [serverError, setServerError] = useState<boolean>(false);

  const requestId = new URLSearchParams(window.location.search).get('request_id') || location.state?.requestId;

  useEffect(() => {
    if (!isLocalEnv) {
      handleCsrf();
    }
  }, []);

  const updateUserAuthnSelection = async (authnPath:string, authnMethod:string, csrfToken: string) => {
    const verifyUserAuthnUpdate = await handleUserAuthnSelection(authnMethod, csrfToken);
    if (verifyUserAuthnUpdate) {
      navigate(authnPath, {state: {requestId: requestId, csrfToken: csrfToken}});
    } else {
      setServerError(true);
    }
  };

  const handleCsrf = async () => {
    if (csrfToken) return;
    const token = await fetchAuthenticationCsrfToken();
    if (token != '') {
      setCsrfToken(token);
    } else {
      setServerError(true);
    }
  };

  const header: JSX.Element =
      <Header
        variant="h1"
        description={bundle.getMessage('authentication_landing_page_description')}
      >
        {bundle.getMessage('authentication_landing_page_title',
            {title: 'authentication_landing_page_title'},
        )}
      </Header>;

  return (
    <ResponsiveContentLayout>
      {serverError ?
      <InternalServerError/> :
      <CenteredContainer header={header}>
        <div data-css={isMobile ? 'mobile-col' : 'col'}>
          <Button
            variant='primary'
            fullWidth
            iconSvg={<img src={qrCodeIcon}/>}
            onClick={() => updateUserAuthnSelection('/authentication/tac', 'tacAndPin', csrfToken)}
          >
            {bundle.getMessage('authentication_with_tac')}
          </Button>
          <Button
            variant='primary'
            fullWidth
            iconSvg={<img src={usbIcon}/>}
            onClick={() => updateUserAuthnSelection('/authentication/fido2', 'fido2', csrfToken)}
          >
            {bundle.getMessage('authentication_with_fido2')}
          </Button>
        </div>
      </CenteredContainer>
      }
    </ResponsiveContentLayout>

  );
};

export default AuthenticationLandingPage;
